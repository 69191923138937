// ClientsList.js

import React, { useEffect, useState, useContext } from 'react';
import axiosInstance from '../../../axios/axiosConfig';
import AuthContext from "../../../context/AuthContext";
import { Link } from "react-router-dom";
import "./ClientsList.css";

function ClientList() {
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(true);
    const authContext = useContext(AuthContext);

    useEffect(() => {
        if (authContext.isAdmin) {
            const fetchClients = async () => {
                try {
                    const response = await axiosInstance.get('/api/clients');
                    let fetchedClients = Array.isArray(response.data.clients) ? response.data.clients : [];

                    // Ταξινόμηση των clients αλφαβητικά βάσει του επωνύμου και στη συνέχεια του ονόματος
                    fetchedClients.sort((a, b) => {
                        // Καθορίζουμε το locale βάσει του πρώτου χαρακτήρα των ονομάτων
                        const locale = getLocale(a.firstName, b.firstName);

                        // Κάνουμε την σύγκριση πρώτα για το firstName και μετά για το lastName
                        return a.firstName.localeCompare(b.firstName, locale) || a.lastName.localeCompare(b.lastName, locale);
                    });

                    function getLocale(a, b) {
                        // Έλεγχος αν και τα δύο ονόματα έχουν ελληνικό πρώτο χαρακτήρα
                        if (/^[Α-Ωα-ω]/.test(a[0]) && /^[Α-Ωα-ω]/.test(b[0])) {
                            return 'el';
                        }
                        // Έλεγχος αν και τα δύο ονόματα έχουν λατινικό πρώτο χαρακτήρα
                        if (/^[A-Za-z]/.test(a[0]) && /^[A-Za-z]/.test(b[0])) {
                            return 'en';
                        }
                        // Αν τα ονόματα είναι μικτά, χρησιμοποιούμε το 'el' για να διατηρήσουμε τα ελληνικά πρώτα
                        return 'el';
                    }

                    // Ενημέρωση της κατάστασης με τα ταξινομημένα δεδομένα
                    setClients(fetchedClients);

                    setLoading(false);
                } catch (error) {
                    console.error('Error fetching clients:', error);
                    setLoading(false);
                }
            };

            fetchClients();
        } else {
            setLoading(false);
        }
    }, [authContext.isAdmin, clients.length]);

    return (
        <div className="container-clients">
            {loading ? (
                <p>Φόρτωση...</p>
            ) : (
                <>

                    <Link to="/add-client">
                        <button className="add-client-btn"><i className="fa fa-plus"></i> Προσθήκη νέου πελάτη</button>
                    </Link>
                    {clients.length === 0 && (
                        <p>Δεν βρέθηκαν πελάτες. Παρακαλώ προσθέστε κάποιους!</p>
                    )}
                    {clients.map((client, index) => (
                        <Link
                            to={`/clients/${client._id}`}
                            key={client._id}
                            className="property-link"
                            style={{
                                backgroundColor: index % 2 === 0 ? '#f0f0f0' : '#ffffff',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                width: '100%',
                                padding: '10px',
                                boxSizing: 'border-box'
                            }}
                        >
                            <div style={{ marginBottom: '5px' }}>{client.firstName} {client.lastName}</div>
                            <div style={{ fontSize: '0.8em', color: '#555' }}>{client.phone}</div>
                            <div style={{ fontSize: '0.8em', fontStyle: 'italic', color: '#999' }}>{client.notes}</div>
                        </Link>
                    ))}

                </>
            )}
        </div>
    );
}


export default ClientList;
