// PropertiesList.js

import React, { useEffect, useState, useContext } from "react";
import PropertyCard from "./PropertyCard";
import { useLocation, Link } from "react-router-dom";
import "./PropertiesList.css";
import axiosInstance from "../../../axios/axiosConfig";
import AuthContext from "../../../context/AuthContext";
import { useTranslation } from 'react-i18next';
import '../../ChangeLanguage/i18next.js';
import "./Loading.css";

function PropertiesList() {
  const { t } = useTranslation();
  const [properties, setProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const authContext = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const searchParams = new URLSearchParams(location.search);

        const propertyType = searchParams.get('type');
        const status = searchParams.get('status');
        const locationQuery = searchParams.get('location');
        const locationObject = locationQuery ? JSON.parse(locationQuery) : null;

        const priceTo = parseInt(searchParams.get('priceTo'));
        const priceFrom = parseInt(searchParams.get('priceFrom'));

        const sqMetersTo = parseInt(searchParams.get('sqMetersTo'));
        const sqMetersFrom = parseInt(searchParams.get('sqMetersFrom'));

        const response = await axiosInstance.get("/api/properties");

        // Αν ο χρήστης είναι διαχειριστής, προβάλλουμε όλα τα ακίνητα.
        // Διαφορετικά, προβάλλουμε μόνο τα ακίνητα με visibility === true
        let filteredProperties = authContext.isAdmin
          ? response.data
          : response.data.filter(property => property.visibility === true);

        if (status) {
          filteredProperties = filteredProperties.filter(property => property.status === status);
        }
        // Παράδειγμα τροποποιημένης λογικής φιλτραρίσματος
        if (propertyType) {
          const propertyTypeObject = JSON.parse(propertyType);
          filteredProperties = filteredProperties.filter(property =>
            property.type.GR === propertyTypeObject.GR || property.type.EN === propertyTypeObject.EN
          );
        }

        if (locationObject) {
          const normalizedInputGR = normalizeString(locationObject.GR);
          const normalizedInputEN = normalizeString(locationObject.EN);

          filteredProperties = filteredProperties.filter(property =>
            normalizeString(property.location.GR).startsWith(normalizedInputGR) ||
            normalizeString(property.location.EN).startsWith(normalizedInputEN)
          );
        }

        if (priceTo) {
          filteredProperties = filteredProperties.filter(property => property.price <= priceTo);
        }
        if (priceFrom) {
          filteredProperties = filteredProperties.filter(property => property.price >= priceFrom);
        }

        if (sqMetersTo) {
          filteredProperties = filteredProperties.filter(property => property.sqMeters <= sqMetersTo);
        }
        if (sqMetersFrom) {
          filteredProperties = filteredProperties.filter(property => property.sqMeters >= sqMetersFrom);
        }

        setProperties(filteredProperties);
      } catch (err) {
        // console.error("Error fetching properties: ", err);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [location, authContext.isAdmin]);


  return (
    <div className="container-properties">

      {isLoading ? (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      ) : properties.length === 0 ? (
        <p>{t('Δεν βρέθηκαν ακίνητα με τα δεδομένα κριτήρια. Παρακαλώ αλλάξτε τα φίλτρα σας!')}</p>
      ) : (
        <div className="properties-grid">
          {properties.map((property) => (
            <Link to={`/properties/${property._id}`} key={property._id} className="property-link">
              <PropertyCard property={property} />
            </Link>
          ))}
        </div>
      )}

      {authContext.isAdmin && (
        <Link to="/add-property">
          <button className="add-property-btn"><i className="fa fa-plus"></i> Προσθήκη νέου</button>
        </Link>
      )}

    </div>
  );
}

function normalizeString(str) {
  return str
    .normalize("NFD").replace(/[\u0300-\u036f]/g, "") // Αφαιρεί τους τόνους
    .toLowerCase(); // Μετατρέπει σε πεζά γράμματα
}

export default PropertiesList;
