// useAxiosInterceptor.js

import { useContext, useEffect } from 'react';
import axiosInstance from './axiosConfig';
import AuthContext from '../context/AuthContext';

const useAxiosInterceptor = () => {
    const authContext = useContext(AuthContext);

    useEffect(() => {
        const requestInterceptor = axiosInstance.interceptors.request.use(
            config => {
                const authInfo = JSON.parse(localStorage.getItem('authInfo'));
                if (authInfo?.accessToken) {
                    config.headers['Authorization'] = `Bearer ${authInfo.accessToken}`;
                }
                return config;
            },
            error => Promise.reject(error)
        );

        const responseInterceptor = axiosInstance.interceptors.response.use(
            response => {
                const accessToken = response.headers['X-Access-Token'];
                if (accessToken) {
                    localStorage.setItem('token', accessToken);
                    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
                }
                return response;
            },
            error => {
                if (error.response && error.response.status === 401 && !error.config._retry) {
                    authContext.logout();
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axiosInstance.interceptors.request.eject(requestInterceptor);
            axiosInstance.interceptors.response.eject(responseInterceptor);
        };
    }, [authContext]);
};

export default useAxiosInterceptor;
