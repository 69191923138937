// SocialShareButtons.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faWhatsapp, faViber } from '@fortawesome/free-brands-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './SocialShareButtons.css';

const SocialShareButtons = ({ url, title }) => {
  const encodedTitle = encodeURIComponent(title);
  const encodedUrl = encodeURIComponent(url);

  const socialNetworks = [
    {
      icon: faFacebookF,
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`,
      name: 'Facebook'
    },
    {
      icon: faTwitter,
      url: `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}`,
      name: 'Twitter'
    },
    {
      icon: faViber,
      url: `viber://forward?text=${encodedTitle}%20${encodedUrl}`,
      name: 'Viber'
    },
    {
      icon: faWhatsapp,
      url: `https://wa.me/?text=${encodedTitle}%20${encodedUrl}`,
      name: 'WhatsApp'
    },
  ];

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url).then(() => {
      toast.success('URL copied to clipboard', {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  return (
    <div className="social-share-buttons">
      {socialNetworks.map(network => (
        <a
          key={network.name}
          href={network.url}
          target="_blank"
          rel="noopener noreferrer"
          title={`Share on ${network.name}`}
        >
          <FontAwesomeIcon icon={network.icon} className="fa" />
        </a>
      ))}
      <button onClick={copyToClipboard} title="Copy URL">
        <FontAwesomeIcon icon={faCopy} className="fa" />
      </button>
      <ToastContainer />
    </div>
  );
};

export default SocialShareButtons;
