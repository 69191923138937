// PropertyCard.js

import React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CameraIcon from '@mui/icons-material/Camera';
import './PropertyCard.css';
import { useTranslation } from 'react-i18next';
import '../../ChangeLanguage/i18next.js';

function PropertyCard({ property }) {
    const { t, i18n } = useTranslation();

    if (!property) {
        return null;
    }

    const imageUrl = property.photos && property.photos[0]
        ? `${property.photos[0]}`
        : "path_to_default_image_if_needed";

    const photoCount = property.photos ? property.photos.length : 0;

    function formatNumber(number) {
        // Ελέγχουμε αν η τιμή είναι αριθμός και όχι null ή undefined
        if (number === null || number === undefined || isNaN(number)) {
            // Επιστρέφουμε κενό string αν η τιμή δεν είναι αριθμός
            return '';
        }
        // Καλούμε την toLocaleString αν η τιμή είναι έγκυρος αριθμός
        return number.toLocaleString('de-DE');
    }

    return (
        <Card className="card-root">
            <CardMedia
                component="div"
                style={{ backgroundImage: `url(${imageUrl})` }}
                className="card-media-root"
            >
                {(property.status === 'sold' || property.status === 'reserved') && (
                    <div className="sold-tag">{t(property.status)}</div>
                )}
                <div className="photo-overlay">
                    <CameraIcon className="camera-icon" />
                    <span>{photoCount}</span>
                </div>
            </CardMedia>
            <CardContent className="card-content-root">
                {property.title && (
                    <>
                        <Typography component="div" className="card-title">
                            {i18n.language === 'en' ? property.title.EN : property.title.GR}
                        </Typography>
                    </>
                )}
                {property.description && (
                    <>
                        <Typography variant="body2" color="text.secondary" className="card-description">
                            {i18n.language === 'en' ? property.description.EN : property.description.GR}
                        </Typography>
                    </>
                )}
                {property.type && (
                    <>
                        <Typography variant="body2" className="card-type">
                            {t('Τύπος ακινήτου')}: {i18n.language === 'en' ? property.type.EN : property.type.GR}
                        </Typography>
                    </>
                )}
                <Typography variant="body2" className="card-price">
                    {t('Τιμή')}: {formatNumber(property.price)}
                </Typography>
                {property.type && (
                    <>
                        <Typography variant="body2" className="card-location">
                            {t('Τοποθεσία')}: {i18n.language === 'en' ? property.location.EN : property.location.GR}
                        </Typography>
                    </>
                )}
                <Typography variant="body2" className="card-area">
                    {t('Τετραγωνικά Μέτρα')}: {property.sqMeters} m²
                </Typography>
                <Typography variant="body2" className="card-status">
                    {t('Κατάσταση')}: {t(property.status)}
                </Typography>
            </CardContent>
        </Card>
    );
}

export default PropertyCard;
