// Logout.js

import { useContext, useEffect }  from 'react';
import { useNavigate }            from 'react-router-dom';
import AuthContext                from '../context/AuthContext';

function Logout() {
  const navigate    = useNavigate();
  const authContext = useContext(AuthContext);

  useEffect(() => {
    localStorage.clear();
    authContext.logout();
    navigate('/');
  });

  return null;
}

export default Logout;
