// axiosConfig.js

import axios from 'axios';

export const baseURL = 'https://vercel-psi-dusky.vercel.app';
// https://vercel-psi-dusky.vercel.app
// http://localhost:5002

const axiosInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem("token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    else
      console.warn("Token is missing.");
    return config;
  },
  error => {
    // console.error("Request configuration failed:", error);
    Promise.reject(error);
  }
);

export default axiosInstance;
