// axiosGeocoding.js

import axios from 'axios';

const geocodeLocation = async (address) => {
  const apiKey = '452e579f17184482b1dc04518c8289c5';
  const url = `https://api.opencagedata.com/geocode/v1/json`;

  try {
    const response = await axios.get(url, {
      params: {
        q: address,
        key: apiKey,
      },
    });

    // Βρες τις συντεταγμένες από την πρώτη θέση των αποτελεσμάτων
    const { lat, lng } = response.data.results[0].geometry;
    return { lat, lng };
  } catch (error) {
    // console.error('Geocoding error:', error);
    return null;
  }
};

export default geocodeLocation;