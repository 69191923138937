// AuthContextProvider.js

import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../axios/axiosConfig';
import AuthContext from './AuthContext';
import useTokenRefresh from './useTokenRefresh';

const AuthContextProvider = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [tokenExpiration, setTokenExpiration] = useState(null);

  useEffect(() => {
    const storedAuthInfo = JSON.parse(localStorage.getItem('authInfo'));

    if (storedAuthInfo) {
      setIsAuthenticated(storedAuthInfo.isAuthenticated);
      setIsAdmin(storedAuthInfo.isAdmin);
      setTokenExpiration(storedAuthInfo.tokenExpiration);
    }
    else {
      console.log('No authInfo found in localStorage. User is not authenticated or data is not stored yet.');
    }
  }, [setTokenExpiration]);

  const logoutHandler = useCallback(() => {
    setIsAuthenticated(false);
    setIsAdmin(false);
    setTokenExpiration(null);
    localStorage.removeItem('authInfo');
  }, [setTokenExpiration]);

  const refreshToken = useCallback(async () => {
    try {
      const storedAuthInfo = JSON.parse(localStorage.getItem('authInfo'));

      const response = await axiosInstance.post('/api/admin/refresh-token', {
        token: storedAuthInfo?.newRefreshToken
      });
      const { accessToken, newRefreshToken, isAdmin, tokenExpiration } = response.data;

      setIsAuthenticated(true);
      setIsAdmin(isAdmin);
      setTokenExpiration(new Date(tokenExpiration).getTime());

      localStorage.setItem('authInfo', JSON.stringify({
        ...storedAuthInfo,
        accessToken,
        refreshToken: newRefreshToken,
        tokenExpiration,
        isAdmin
      }));

      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    } catch (err) {
      logoutHandler();
    }
  }, [logoutHandler, setTokenExpiration]);

  useEffect(() => {
    if (tokenExpiration) {
      const currentTime = Date.now();
      const timeToExpiration = tokenExpiration - currentTime;
      if (timeToExpiration <= 0) {
        logoutHandler();
        return;
      }

      const timerId = setTimeout(() => {
        refreshToken();
      }, timeToExpiration - (30 * 1000));

      return () => clearTimeout(timerId);
    }
  }, [tokenExpiration, refreshToken, logoutHandler]);

  const loginHandler = (isAdmin, expiration, refreshTokenReceived) => {
    setIsAuthenticated(true);
    // Μετατροπή του expirationTimestamp που είναι σε UTC σε local time
    const expirationTimestamp = new Date(expiration).getTime();

    if (typeof expirationTimestamp === 'number' && !isNaN(expirationTimestamp)) {
      setIsAdmin(isAdmin);
      setTokenExpiration(expirationTimestamp);

      localStorage.setItem('authInfo', JSON.stringify({
        isAuthenticated: true,
        isAdmin,
        tokenExpiration: expirationTimestamp,
        refreshToken: refreshTokenReceived
      }));

    } else {
      console.error('Invalid token expirationTimestamp timestamp:', expirationTimestamp);
    }
  };

  useTokenRefresh();

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: isAuthenticated,
        isAdmin: isAdmin,
        login: loginHandler,
        logout: logoutHandler,
        refreshToken
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
