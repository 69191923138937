// useTokenRefresh.js

import { useContext, useEffect } from 'react';
import AuthContext from './AuthContext';

const useTokenRefresh = () => {
    const { tokenExpiration, refreshToken, logout } = useContext(AuthContext);

    useEffect(() => {
        if (!tokenExpiration) {
            console.log('No token expiration found. Will not setup refresh.');
            return;
        }

        const currentTime = Date.now();
        // Η ώρα λήξης σε milliseconds
        const expirationTime = new Date(tokenExpiration).getTime();
        // Χρόνος μέχρι τη λήξη
        const timeToExpiration = expirationTime - currentTime;
        
        if (timeToExpiration <= 0) {
            console.log('Token has expired, logging out...');
            logout();
            return;
        }
        try {
            const timerId = setTimeout(refreshToken, timeToExpiration - (30 * 1000));
            return () => {
                clearTimeout(timerId);
            };
        } catch (error) {
            console.error('Error setting up token refresh:', error);
        }

    }, [tokenExpiration, refreshToken, logout]);
};

export default useTokenRefresh;
