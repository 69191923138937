// Home.js

import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import AOS from 'aos';
import Footer from './Footer';
import TipsImage from '../../assets/tips.jpg';
import ChairsImage from '../../assets/keys.jpg';
import AuthContext from '../../context/AuthContext';
import AboutUsImage from '../../assets/about-us.jpg';

import './Home.css';
import 'aos/dist/aos.css';
import '../ChangeLanguage/i18next.js';

const Home = () => {
  const { register, getValues } = useForm();
  const [statusFilter, setStatusFilter] = useState('');
  const [selectedButton, setSelectedButton] = useState('');
  const { t } = useTranslation();
  const [searchLink, setSearchLink] = useState('/properties');
  const authContext = useContext(AuthContext);

  const buildLink = useCallback(() => {
    const data = getValues();
    let queryParams = new URLSearchParams();

    for (let key in data) {
      if (data[key] && data[key] !== "") {
        if (key === "type" || key === "location") {
          const valueObject = { GR: data[key], EN: data[key] };
          queryParams.append(key, JSON.stringify(valueObject));
        } else {
          queryParams.append(key, data[key]);
        }
      }
    }

    if (statusFilter) {
      queryParams.append("status", statusFilter);
    }

    return `/properties?${queryParams.toString()}`;
  }, [getValues, statusFilter]);

  const handleForSaleClick = () => {
    setStatusFilter('for sale');
    setSelectedButton('for sale');
  };

  const handleForRentClick = () => {
    setStatusFilter('for rent');
    setSelectedButton('for rent');
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  useEffect(() => {
    setSearchLink(buildLink());
  }, [buildLink]);

  return (
    <>
      <div className='home-page'>
        <img className="hero-image" src={ChairsImage} alt="Chairs" />
        <div className='content'>
          <h1>AdraxEstate</h1>

          {authContext.isAdmin && (
            <Link to="/clients">
              <button className="add-property-btn">Πελάτες</button>
            </Link>
          )}

          <form className="filters-form" onChange={() => setSearchLink(buildLink())}>
            <div className="filters">
              <div className="type-filter">
                <select defaultValue="" {...register("type")}>
                  <option value="" disabled>            {t('Επιλογή κατηγορίας')}   </option>
                  <option value={t('Κατοικία')}>        {t('Κατοικία')}             </option>
                  <option value={t('Επαγγελματικό')}>   {t('Επαγγελματικό')}        </option>
                  <option value={t('Γη')} >             {t('Γη')}                   </option>
                  <option value={t('Λοιπά ακίνητα')}>   {t('Λοιπά ακίνητα')}        </option>
                </select>
                <input type="text" placeholder={t('Περιοχή')}                       {...register("location")} />
              </div>
              <div className='price-filter'>
                <input type="number" placeholder={t('Τιμή από')}                    {...register("priceFrom")} />
                <input type="number" placeholder={t('Τιμή εώς')}                    {...register("priceTo")} />
              </div>
              <div className='square-filter'>
                <input type="number" placeholder={t('Εμβαδόν από (m²)')}            {...register("sqMetersFrom")} />
                <input type="number" placeholder={t('Εμβαδόν εώς (m²)')}            {...register("sqMetersTo")} />
              </div>
            </div>
          </form>

          <div className='actions'>
            <button type="button"
              className={`btn ${selectedButton === 'for sale' ? 'btn-selected' : 'btn-primary'}`}
              onClick={handleForSaleClick}>{t('Αγορά')}     </button>
            <button type="button"
              className={`btn ${selectedButton === 'for rent' ? 'btn-selected' : 'btn-primary'}`}
              onClick={handleForRentClick}>{t('Ενοικίαση')} </button>
          </div>
          <div className="search-button">
            <Link to={searchLink} className='btn'>{t('Αναζήτηση')}</Link>
          </div>
        </div>
      </div>
      <div className='about-us' data-aos='fade-up'>
        <img className='about-us-image' src={AboutUsImage} alt='About Us' />
        <div className='about-us-content'>
          <h2>{t('Σχετικά με εμάς')}</h2>
          <p>
            {t('Η AdraxEstate είναι μια πρωτοπόρος εταιρεία στον χώρο των ακινήτων που προσφέρει ολοκληρωμένες υπηρεσίες. Βασιζόμαστε στην εμπειρία, την τεχνογνωσία και την προσωπική εξυπηρέτηση για να προσφέρουμε τις καλύτερες λύσεις στους πελάτες μας.')}
          </p>
        </div>
      </div>
      <div id='tips' className='about-us' data-aos='fade-right'>
        <div className='about-us-content'>
          <h2>{t('Σύμβουλος Ακινήτων')}</h2>
          <p>
            {t('Προσφέρουμε εξατομικευμένες συμβουλευτικές υπηρεσίες στον τομέα της ακίνητης περιουσίας, βοηθώντας σας να βρείτε την καλύτερη λύση για τις ανάγκες σας.')}
          </p>
        </div>
        <img src={TipsImage} className='tips-image' alt='About Us' />
      </div>

      <Footer />
    </>
  );
};

export default Home;
