// i18next.js

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: {

                    // Navbar.js
                    "Αρχική": "Home",
                    "Ακίνητα": "Properties",
                    "Εγγραφή": "Register",
                    "Σύνδεση": "Login",
                    "Αποσύνδεση": "Logout",

                    // Home.js
                    "Επιλογή κατηγορίας": "Category selection",
                    "Κατοικία": "House",
                    "Επαγγελματικό" : "Business",
                    "Γη": "Land",
                    "Λοιπά ακίνητα": "Other",

                    "Περιοχή": "Location",
                    "Τιμή από": "Price from",
                    "Τιμή εώς": "Price to",
                    "Εμβαδόν από (m²)": "Square m from (m²)",
                    "Εμβαδόν εώς (m²)": "Square m to (m²)",

                    "Αγορά": "Buy",
                    "Ενοικίαση": "Rent",
                    "Αναζήτηση": "Search",

                    // After filter form
                    "Σχετικά με εμάς": "About us",
                    "Η AdraxEstate είναι μια πρωτοπόρος εταιρεία στον χώρο των ακινήτων που προσφέρει ολοκληρωμένες υπηρεσίες. Βασιζόμαστε στην εμπειρία, την τεχνογνωσία και την προσωπική εξυπηρέτηση για να προσφέρουμε τις καλύτερες λύσεις στους πελάτες μας.": "AdraxEstate is a pioneering company in the real estate sector offering comprehensive services. We rely on experience, expertise, and personalized service to provide the best solutions to our clients.",
                    "Σύμβουλος Ακινήτων": "Real Estate Advisor",
                    "Προσφέρουμε εξατομικευμένες συμβουλευτικές υπηρεσίες στον τομέα της ακίνητης περιουσίας, βοηθώντας σας να βρείτε την καλύτερη λύση για τις ανάγκες σας.": "We offer personalized advisory services in the field of real estate, assisting you in finding the best solution for your needs.",
                    
                    // Footer.js
                    "Στοιχεία επικοινωνίας": "Contact Info",

                    // PropertiesList.js
                    "Δεν βρέθηκαν ακίνητα με τα δεδομένα κριτήρια. Παρακαλώ αλλάξτε τα φίλτρα σας!": "No properties found with the given criteria. Please change your filters!",

                    // PropertyCard.js
                    "Τύπος ακινήτου": "Property type",
                    "Τιμή": "Price",
                    "Τοποθεσία": "Location",
                    "Τετραγωνικά Μέτρα": "Square Meters",
                    "Κατάσταση": "Status",

                    // PropertyCard.js vars
                    "for sale": "For Sale",
                    "for rent": "For Rent",
                    "sold": "Sold",
                    "rented": "Rented",
                    "reserved": "Reserved",

                    // PropertyDetails.js
                    "Περιγραφή": "Details",
                    "Εμφάνιση Περισσότερων": "Show More",
                    "Εμφάνιση Λιγότερων": "Show Less",
                    "Απόσταση από τη Θάλασσα": "Distance from the sea",
                    "Σύστημα Θέρμανσης": "Heating System",
                    "Έτος Κατασκευής": "Year of Construction",
                    "Ενεργειακή Κλάση": "Energy Class",
                    "Κωδικός Ακινήτου": "Property Code",
                    "Υπνοδωμάτια": "Bedrooms",
                    "Όροφος": "Floor",
                    "Κουζίνες": "Kitchens",
                    "Σαλόνια": "Living Rooms",
                    "Μπάνια": "Bathrooms",
                    "Κατάσταση ακινήτου": "Property Status",
                    "Επιτρέπονται Κατοικίδια": "Pets Allowed",

                    "Περιλαμβάνονται επίσης": "Also included",

                    "Ηλιακός Θερμοσίφωνας": "Solar Water Heater",
                    "Ενδοδαπέδια Θέρμανση": "Underfloor Heating",
                    "Ανελκυστήρας": "Elevator",
                    "Κλιματισμός": "Air Conditioning",
                    "Έπιπλα": "Furniture",
                    "Αποθήκη": "Storage",
                    "Σοφίτα": "Attic",
                    "Σίτες": "Window Sieves",
                    "Τζάκι": "Fireplace",
                },
            },
            el: {
                translation: {
                    
                    // Navbar.js
                    "Αρχική": "Αρχική",
                    "Ακίνητα": "Ακίνητα",
                    "Εγγραφή": "Εγγραφή",
                    "Σύνδεση": "Σύνδεση",
                    "Αποσύνδεση": "Αποσύνδεση",

                    //  Home.js
                    "Επιλογή κατηγορίας": "Επιλογή κατηγορίας",
                    "Κατοικία": "Κατοικία",
                    "Επαγγελματικό" : "Επαγγελματικό",
                    "Γη": "Γη",
                    "Λοιπά ακίνητα": "Λοιπά ακίνητα",

                    "Περιοχή": "Περιοχή",
                    "Τιμή από": "Τιμή από",
                    "Τιμή έως": "Τιμή έως",
                    "Εμβαδόν από (m²)": "Εμβαδόν από (m²)",
                    "Εμβαδόν εώς (m²)": "Εμβαδόν εώς (m²)",

                    "Αγορά": "Αγορά",
                    "Ενοικίαση": "Ενοικίαση",
                    "Αναζήτηση": "Αναζήτηση",

                    // After filter form
                    "Σχετικά με εμάς": "Σχετικά με εμάς",
                    "Η AdraxEstate είναι μια πρωτοπόρος εταιρεία στον χώρο των ακινήτων που προσφέρει ολοκληρωμένες υπηρεσίες. Βασιζόμαστε στην εμπειρία, την τεχνογνωσία και την προσωπική εξυπηρέτηση για να προσφέρουμε τις καλύτερες λύσεις στους πελάτες μας.": "Η AdraxEstate είναι μια πρωτοπόρος εταιρεία στον χώρο των ακινήτων που προσφέρει ολοκληρωμένες υπηρεσίες. Βασιζόμαστε στην εμπειρία, την τεχνογνωσία και την προσωπική εξυπηρέτηση για να προσφέρουμε τις καλύτερες λύσεις στους πελάτες μας.",
                    "Σύμβουλος Ακινήτων": "Σύμβουλος Ακινήτων",
                    "Προσφέρουμε εξατομικευμένες συμβουλευτικές υπηρεσίες στον τομέα της ακίνητης περιουσίας, βοηθώντας σας να βρείτε την καλύτερη λύση για τις ανάγκες σας.": "Προσφέρουμε εξατομικευμένες συμβουλευτικές υπηρεσίες στον τομέα της ακίνητης περιουσίας, βοηθώντας σας να βρείτε την καλύτερη λύση για τις ανάγκες σας.",

                    // Footer.js
                    "Στοιχεία επικοινωνίας": "Στοιχεία επικοινωνίας",

                    // PropertiesList.js
                    "Δεν βρέθηκαν ακίνητα με τα δεδομένα κριτήρια. Παρακαλώ αλλάξτε τα φίλτρα σας!": "Δεν βρέθηκαν ακίνητα με τα δεδομένα κριτήρια. Παρακαλώ αλλάξτε τα φίλτρα σας!",

                    // PropertyCard.js
                    "Τύπος ακινήτου": "Τύπος ακινήτου",
                    "Τιμή": "Τιμή",
                    "Τοποθεσία": "Τοποθεσία",
                    "Τετραγωνικά Μέτρα": "Τετραγωνικά Μέτρα",
                    "Κατάσταση": "Κατάσταση",

                    // PropertyCard.js vars
                    "for sale": "Προς Πώληση",
                    "for rent": "Προς Ενοικίαση",
                    "sold": "Πωλήθηκε",
                    "rented": "Ενοικιάστηκε",
                    "reserved": "Κρατημένο",

                    // PropertyDetails.js
                    "Περιγραφή": "Περιγραφή",
                    "Εμφάνιση Περισσότερων": "Εμφάνιση Περισσότερων",
                    "Εμφάνιση Λιγότερων": "Εμφάνιση Λιγότερων",
                    "Απόσταση από τη Θάλασσα": "Απόσταση από τη θάλασσα",
                    "Σύστημα Θέρμανσης": "Σύστημα Θέρμανσης",
                    "Έτος Κατασκευής": "Έτος Κατασκευής",
                    "Ενεργειακή Κλάση": "Ενεργειακή Κλάση",
                    "Κωδικός Ακινήτου": "Κωδικός Ακινήτου",
                    "Υπνοδωμάτια": "Υπνοδωμάτια",
                    "Όροφος": "Όροφος",
                    "Κουζίνες": "Κουζίνες",
                    "Σαλόνια": "Σαλόνια",
                    "Μπάνια": "Μπάνια",
                    "Κατάσταση ακινήτου": "Κατάσταση ακινήτου",
                    "Επιτρέπονται Κατοικίδια": "Επιτρέπονται Κατοικίδια",

                    "Περιλαμβάνονται επίσης": "Περιλαμβάνονται επίσης",

                    "Ηλιακός Θερμοσίφωνας": "Ηλιακός Θερμοσίφωνας",
                    "Ενδοδαπέδια Θέρμανση": "Ενδοδαπέδια Θέρμανση",
                    "Ανελκυστήρας": "Ανελκυστήρας",
                    "Κλιματισμός": "Κλιματισμός",
                    "Έπιπλα": "Έπιπλα",
                    "Αποθήκη": "Αποθήκη",
                    "Σοφίτα": "Σοφίτα",
                    "Σίτες": "Σίτες",
                    "Τζάκι": "Τζάκι",
                },
            },
        },
        lng: "en", // default language
        fallbackLng: "en", // use English if the language can't be determined
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
