// Map.js

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import React, { useState, useEffect } from 'react';
import geocodeLocation from '../../../axios/axiosGeocoding';

// Fix the default marker icon issue with Webpack
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const Map = ({ property }) => {
    // Define the state and the setter for position
    const [position, setPosition] = useState(null); // Initial state is null

    useEffect(() => {
        const fetchCoordinates = async () => {
            // Make sure to send the proper address string to geocodeLocation
            const coords = await geocodeLocation(property.location.EN); // Assuming 'property.address' is a string
            if (coords) {
                setPosition([coords.lat, coords.lng]);
            }
        };

        if (property.location.EN) {
            fetchCoordinates();
        }
    }, [property.location.EN]); // React to changes in property.address, not property.location.EN

    if (!position) {
        return <div>Loading map...</div>;
    }

    return (
        <MapContainer center={position} zoom={13} style={{ height: '50vh', width: '100%' }}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={position}>
                <Popup>
                    {property.name}
                </Popup>
            </Marker>
        </MapContainer>
    );
};

export default Map;
