// ClientEdit.js

import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../axios/axiosConfig';
import { useParams, useNavigate } from 'react-router-dom';
import './ClientEdit.css';

function ClientEdit() {
    const [clientData, setClientData] = useState({ firstName: '', lastName: '', notes: '', phone: '' });
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchClientData = async () => {
            try {
                const response = await axiosInstance.get(`/api/clients/${id}`);
                setClientData(response.data.client || {});
            } catch (error) {
                console.error('Error fetching client data:', error);
            }
        };

        fetchClientData();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setClientData({
            ...clientData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await axiosInstance.put(`/api/clients/update/${clientData._id}`, clientData);
            navigate(-1);
        } catch (error) {
            console.error('Error updating client data:', error);
        }
    };

    const handleDelete = async () => {
        if (window.confirm("Are you sure you want to delete this client?")) {
            try {
                await axiosInstance.delete(`/api/clients/delete/${clientData._id}`);
                navigate(-1);
            } catch (error) {
                console.error('Error deleting client data:', error);
            }
        }
    };

    return (
        <div className="client-edit-container">
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>First Name</label>
                    <input type="text" name="firstName" value={clientData.firstName} onChange={handleChange} />
                </div>
                <div className="form-group">
                    <label>Last Name</label>
                    <input type="text" name="lastName" value={clientData.lastName} onChange={handleChange} />
                </div>
                <div className="form-group">
                    <label>Notes</label>
                    <textarea name="notes" value={clientData.notes} onChange={handleChange}></textarea>
                </div>
                <div className="form-group">
                    <label>Phone</label>
                    <input type="text" name="phone" value={clientData.phone} onChange={handleChange} />
                </div>
                <button className='btn' type="submit">Update Client</button>
                <button type="button" className='btn btn-danger' onClick={handleDelete}>Delete Client</button>
            </form>
        </div>
    );
}

export default ClientEdit;
