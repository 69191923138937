// ClientForm.js

import React, { useState } from 'react';
import axiosInstance from '../../../axios/axiosConfig';
import './ClientForm.css';
import { useNavigate } from 'react-router-dom';

function NewClientForm() {
    const navigate = useNavigate();
    const [clientData, setClientData] = useState({
        firstName: '',
        lastName: '',
        notes: '',
        phone: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setClientData({
            ...clientData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
    
        if (token) {
            try {
                await axiosInstance.post('/api/clients/create', clientData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                alert('Client created successfully');
                navigate(-1);
            } catch (error) {
                console.error('Error creating client:', error);
                alert('Failed to create client.');
            }
        } else {
            console.error('No token available.');
            alert('You are not logged in.');
        }
    };
    
    return (
        <div className='client-add-container'>
            <h1 className="client-add-title">Προσθήκη Πελάτη</h1>
            <form className="client-add-form" onSubmit={handleSubmit}>
                <label className="property-edit-label">
                    <input placeholder="FirstName" type="text" name="firstName" value={clientData.firstName} onChange={handleChange} />
                </label>
                <label className="property-edit-label">
                    <input placeholder="LastName" type="text" name="lastName" value={clientData.lastName} onChange={handleChange} />
                </label>
                <label className="property-edit-label">
                    <input className='property-add-textarea' placeholder="Notes" type="text" name="notes" value={clientData.notes} onChange={handleChange} />
                </label>
                <label className="property-edit-label">
                    <input placeholder="Phone" type="number" name="phone" value={clientData.phone} onChange={handleChange} />
                </label>
                <button className="btn submit-btn" type="submit">Create Client</button>
            </form>
        </div>
    );
}

export default NewClientForm;
